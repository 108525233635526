@use 'terra';

$font-family: 'Nunito Sans';
$row-active-color: terra.color('neutral-light', '400');
$row-hover-color: rgba($row-active-color, 0.5);

.ag-theme-ninety {
  --ag-borders: none;
  --ag-header-background-color: terra.color('white');
  --ag-header-column-separator-display: block;
  --ag-header-column-separator-color: #{terra.color('border')};
  --ag-row-hover-color: #{$row-hover-color};

  .ag-header {
    font-family: $font-family;
  }

  .ag-header-row {
    border-bottom: 1px solid terra.color('border');
    color: terra.color('text', 'light');
    height: 40px;
  }

  .ag-header-group-cell {
    @include terra.text(body);
    background-color: terra.color('neutral-light', 100);
    // height is handled by gridOptions
  }

  .ag-header-cell {
    @include terra.text(body);

    &.future-header {
      font-style: italic;
      border-bottom: 2px solid terra.color('neutral-heavy', 300);
    }
  }

  .ag-header-cell-label {
    // Centers the text in the header cell
    justify-content: center;

    // We add a \n to the score date header text so they wrap, this preserves that line-break
    .ag-header-cell-text {
      white-space: pre-wrap !important;
      padding-bottom: 1px;
    }
  }

  // Target only the right-click context menu, not filter menus
  .ag-menu:not(:has(.ag-filter)) {
    background-color: terra.color('white');

    .ag-menu-option {
      height: 36px;
      font-size: 14px;
      font-family: $font-family;
      font-weight: 400;
    }
  }

  .ag-row {
    background-color: terra.color('white');

    &.active-row {
      background-color: $row-active-color;
    }

    .active-col {
      justify-content: flex-end; // Make sure the checkbox is right aligned
      padding-left: 0px; // remove padding so the column width isn't too wide
      padding-right: 0px;
    }

    .active-col.is-active {
      // Uses pseudo element to avoid layout shifting the aggrid drag-handle
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        border-left: 5px solid terra.color('ninety');
        border-top: 0;
        border-bottom: 0;
      }
    }

    // Only show the drag handle when hovering over a row to be consistent with the rest of the app
    // rather than aggrid default
    .ag-drag-handle {
      .ag-icon-grip {
        visibility: hidden;
      }
    }

    &.ag-row-hover {
      .ag-drag-handle {
        .ag-icon-grip {
          visibility: visible;
        }
      }
    }
  }

  .ag-cell {
    @include terra.text(body);
    border-right: 1px solid terra.color('border');
    display: flex;
    align-items: center;

    &.on-track-accent {
      border-top: 0;
      border-bottom: 0;
      border-left: 5px solid terra.color('green');
    }

    &.at-risk-accent {
      border-top: 0;
      border-bottom: 0;
      border-left: 5px solid terra.color('yellow');
    }

    &.off-track-accent {
      border-top: 0;
      border-bottom: 0;
      border-left: 5px solid terra.color('red');
    }

    // Right align values in score columns, applies to renderer state
    &.numeric-align-column {
      justify-content: flex-end;
    }

    // On/off track styles only apply to renderer state, not when editing
    &.score-cell.ag-cell-not-inline-editing {
      cursor: pointer;

      // Support ranged values that use newlines, similar to the header
      white-space: pre;
      text-align: right;

      &.has-custom-goal {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          border-right: 10px solid transparent;
          border-top: 10px solid terra.color('neutral-heavy', '200');
        }
      }

      &.disabled-cell {
        &:not(.future-cell) {
          cursor: default;
        }
        background-color: terra.color('neutral-light', '300');

        &:hover {
          background-color: terra.color('neutral-light', '500');
        }
      }

      &:not(.disabled-cell):hover {
        border: 1px solid terra.color('border', 'heavy');
        border-radius: terra.radius(small);
      }

      &.on-track {
        background-color: terra.color('green', 'shader');
        color: terra.color('green', 'heaviest');

        &.has-custom-goal {
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            border-right: 10px solid transparent;
            border-top: 10px solid terra.color('green', 'heaviest');
          }
        }

        &:not(.disabled-cell):hover {
          border: 1px solid terra.color('green', 'heaviest');
          border-radius: terra.radius(small);
        }
      }

      &.off-track {
        background-color: terra.color('red', 'shader');
        color: terra.color('red', 'heaviest');

        &.has-custom-goal {
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            border-right: 10px solid transparent;
            border-top: 10px solid terra.color('red', 'heaviest');
          }
        }

        &:not(.disabled-cell):hover {
          border: 1px solid terra.color('red', 'heaviest');
          border-radius: terra.radius(small);
        }
      }
    }
  }

  // Right align values while being ediged
  .ag-cell-edit-wrapper {
    .ag-input-field-input,
    .ag-text-field-input {
      text-align: right;
      padding-right: var(--ag-cell-horizontal-padding);
    }
  }

  .right-aligned-editor input {
    text-align: right;
  }

  // Custom header border for last pinned column
  .ag-pinned-left-header {
    border-right: 2px solid terra.color('ninety');
  }

  // Custom cell border for last pinned column
  .ag-pinned-left-cols-container .ag-cell.ag-cell-last-left-pinned {
    border-right: 2px solid terra.color('ninety');
    border-top: 0;
    border-bottom: 0;
  }

  // Update font inside the filter popup to match Terra
  .ag-filter {
    font-family: $font-family;

    // Change the filter checkbox to match the brand
    .ag-checkbox-input-wrapper.ag-checked::after {
      color: terra.color('brand');
    }
  }

  // Change the bottom border color of the filter action header to brand
  .ag-tabs {
    .ag-tab.ag-tab-selected {
      border-bottom-color: terra.color('brand');
    }
  }
}
