@use 'terra';
// Universal Create Dialog

// Material UI inlines a max-width on the dialog
// forcing us to play !important wars, yay
.create-dialog {
  max-width: 100vw !important;
  .mat-mdc-dialog-surface {
    overflow: hidden !important;
  }

  &.dialog-collapsed {
    mat-dialog-container {
      margin: 0 0 -1.5rem;
      width: 100vw;

      @include gt-sm {
        width: 25rem;
      }
    }
  }

  &.dialog-expanded {
    max-height: 100vh !important;
    max-width: 100vw !important;
    height: 540px !important;
    width: 644px !important;

    @include gt-sm {
      max-height: initial;
      max-width: initial;
      height: initial;
      width: initial;
    }
  }

  &.dialog-minimized {
    max-height: 100vh !important;
    max-width: 100vw !important;
    height: 100%;
    width: 100%;

    @include gt-sm {
      max-width: 35rem !important;
      max-height: 38.571rem !important; //540px
    }
  }

  #universal-create-dialog {
    .mat-dialog-content {
      padding: 0 24px;
    }

    &.expanded {
      .mat-dialog-content {
        max-height: 408px; // Set height of content to restrict size of entire dialog
      }
    }

    &.mini {
      .mat-dialog-content {
        @include gt-sm {
          max-height: 29.143rem; //408px
        }
      }
    }

    &.collapsed {
      .actions {
        .expand-to-full {
          @include gt-sm {
            display: none;
          }
        }
      }
    }

    &.mini,
    &.collapsed {
      mat-dialog-container {
        border-top: 4px solid terra.color('brand');
      }

      mat-toolbar {
        border-top: 5px solid terra.color('brand');
      }
    }

    ninety-item-type-select {
      mat-form-field {
        .mat-form-field-wrapper {
          padding: 0;

          .mat-form-field-infix {
            padding: 0;
            margin: 0;
            border-top: 0;
            width: 10rem;

            // Shrinks dropdown arrow to selected items width
            .mat-select-value {
              display: inline !important;
              padding: 1px 0;
            }
          }
        }
      }
    }
  }

  /* These rules apply overrides for the Terra version of the dialog
   * But they only work if the legacy rules are still in place
   * When the legacy rules are removed these will need to be updated to have the other
   * non-overridden styles from above
   */
  &:has(.universal-create) {
    &.dialog-minimized {
      @include gt-sm {
        max-width: 640px !important;
        max-height: 700px !important;
      }
    }

    &.dialog-expanded {
      height: 832px !important;
      width: 960px !important;
    }
  }
}

.ninety-dialog-header {
  .close-dialog {
    margin: -4px -4px 0;
    color: terra.color('text', 'lightest');
  }
}

// Terra based dialog layout
// These are just overrides of the above styles
// if the above styles are removed these won't fully work
.create-dialog:has(.universal-create) {
  &.dialog-collapsed {
    mat-dialog-container {
      margin: 0;
    }
  }
}

.pricing-tier-modal-container {
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: 100vh;
  }
}
