@use '@angular/material' as mat;
@use 'terra';

ninety-issues,
ninety-issues-list-new {
  mat-list-option.list-item span.mdc-list-item__content {
    padding-right: 16px !important;
  }
}

.issue-rating {
  [panelClass='issue-rating-select'] {
    .mat-mdc-select-value {
      @include terra.text(body);
    }
  }
}

ninety-issues-list-new .issue-rating .mat-mdc-form-field-infix {
  padding-top: 0.75rem !important;
}

ninety-issue-card .issue-rating .mdc-text-field {
  padding-bottom: 0.3rem !important;
}

[data-styling^='issues-list_item'] {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
  }

  .issue-rating {
    .mdc-text-field {
      padding: 0 !important;
      &.mdc-text-field--filled {
        background-color: transparent;
      }
    }
  }

  .mdc-list-item__end {
    display: none !important;
  }
}

ninety-issue-card {
  .send-issue-to-team-btn {
    text-align: left;
    padding: 0 !important;
    height: 40px !important;
    width: 40px !important;

    mat-icon.mat-icon {
      color: terra.color('brand') !important;
    }

    mat-select {
      display: inline !important;

      .mat-mdc-select-arrow-wrapper {
        display: none;
      }
    }
  }
  ninety-item-comments .comments-container {
    margin: 0 !important;
    background-color: terra.color(white) !important;
  }
  .item-details {
    margin-bottom: 15px;

    .team-slider {
      margin-bottom: 0;
    }

    .who {
      @include mat.form-field-density(-1);
      .mat-mdc-text-field-wrapper {
        label {
          top: 24px !important;
          bottom: 5px;
          @include terra.text(body);
        }
      }
      .mat-mdc-form-field-subscript-wrapper {
        @include terra.text(body-tiny);
        height: 0;
      }
    }
  }
}

.cdk-overlay-pane:has(.mat-mdc-select-panel.issue-rating-select) {
  width: 8.5rem !important;
}

.cdk-overlay-pane:has(.mat-mdc-select-panel.send-issue-to-team-select) {
  width: 20rem !important;
  top: 12.5rem !important;
}

mat-dialog-container.cdk-dialog-container .mat-mdc-dialog-surface:has(ninety-issue-list-settings-dialog) {
  padding: 24px;
  h2 {
    padding-inline: 0;
    padding-bottom: 0;
  }

  .close-dialog {
    padding: 9px 0 0 1px;
  }

  mat-checkbox {
    @include mat.checkbox-density(-4);

    line-height: 21px;
    .mdc-checkbox .mdc-checkbox__native-control:enabled:focus:checked ~ .mdc-checkbox__background,
    .mdc-checkbox .mdc-checkbox__native-control:enabled:focus:indeterminate ~ .mdc-checkbox__background {
      background-color: terra.color('brand') !important;
      border-color: terra.color('brand') !important;
    }

    .mdc-checkbox
      .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
      ~ .mdc-checkbox__background {
      background-color: transparent !important;
      border-color: terra.color('text', 'light') !important;
    }

    .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
    .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
    .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate='true']:enabled ~ .mdc-checkbox__background {
      background-color: terra.color('brand') !important;
      border-color: terra.color('brand') !important;
    }
  }

  mat-hint {
    display: block;
    margin-left: 28px;
    color: terra.color('text', 'light');
    @include terra.text(body-small);
  }

  #save-issue-settings-btn {
    color: terra.color('white');
    &.disabled {
      color: terra.color('text', 'lightest') !important;
    }
  }
}
