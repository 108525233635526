@use 'terra';

ninety-learning-modules-page {
  #learning-modules-page {
    .message-box {
      width: 384px;
    }
  }
}

.details-route ninety-learning-modules-page {
  #learning-modules-page {
    height: 100% !important;
  }
  height: 100% !important;
}

ninety-learning-tasks-table {
  .mat-mdc-table {
    .task-indent {
      ninety-icon svg {
        width: 56px !important;
      }
    }
  }
}

.kst-avatar-menu {
  border-radius: terra.radius(medium) !important;
  box-shadow: terra.elevation(3) !important;
}

ninety-learning-multiple-todos-dialog {
  mat-toolbar.toolbar {
    .toolbar-left {
      h2 {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
      }
    }
  }

  ninety-chip-team-select-box {
    ninety-select-clear-button {
      display: none;
      visibility: hidden;
    }
  }

  ninety-chip-team-select-box,
  ninety-chip-user-select-box {
    ninety-chip {
      .ninety-chip-ds {
        background-color: terra.color('neutral-shader', 'heavy') !important;
        color: terra.color('text') !important;
      }
    }

    &.invalid {
      ninety-chip-select-kitchen-sink {
        ninety-select-box {
          div.ninety-input--outline {
            outline: 1px solid red !important;
          }
        }
      }
    }
  }

  mat-form-field.due-date-field {
    margin: 0 !important;
    height: 36px;

    div.mat-mdc-text-field-wrapper {
      height: 36px;

      div.mat-mdc-form-field-flex {
        height: 36px;

        div.mat-mdc-form-field-infix {
          min-height: 36px;
          height: 36px;
          padding-top: 6px !important;
        }

        div.mat-mdc-form-field-icon-suffix {
          height: 36px;

          button {
            height: 36px;
            width: 36px;
            padding-top: 5px;
          }
        }
      }
    }

    &.invalid {
      div.mat-mdc-text-field-wrapper {
        outline: 1px solid red;
        border-radius: 4px;
      }
    }
  }

  .cancel {
    width: 160px !important;
  }
}
